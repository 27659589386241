import React from "react";
import {NavLink, useHistory, useLocation} from "react-router-dom";
import {useSelector, useDispatch} from "react-redux";

import {unsetUser} from "../redux/actions/user";

import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import Collapse from "@material-ui/core/Collapse";

import CalendarIcon from "@material-ui/icons/Event";
import LogoutIcon from "@material-ui/icons/ExitToApp";
import MeetingRoomIcon from "@material-ui/icons/MeetingRoom";
import LocationIcon from "@material-ui/icons/Room";
import PeopleIcon from "@material-ui/icons/People";
import BusinessIcon from "@material-ui/icons/Business";
import FeaturesIcon from "@material-ui/icons/PlaylistAddCheck";
import CanteensIcon from "@material-ui/icons/Restaurant";
import ReceptionsIcon from '@material-ui/icons/Announcement';
import BookingReportsIcon from '@material-ui/icons/ListAlt';

import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";

import {makeStyles} from "@material-ui/core/styles";
import theme from "../theme";

const useStyles = makeStyles((theme) => ({
    brand: {
        fontWeight: "600",
        fontSize: "1.5rem",
        color: theme.palette.primary.main,
        textDecoration: "none",
    },
    listItem: {
        "&.active": {
            backgroundColor: "rgba(0, 0, 0, 0.08)",
        },
    },
    noOverflow: {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    bigDivider: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    toolbar: theme.mixins.toolbar,
    nested: {
        paddingLeft: theme.spacing(4),
    },
}));

const SuperAdminMenu = [
    {
        key: "sa-menu-0",
        path: "/manage-users",
        label: "Manage Users",
        Icon: PeopleIcon,
    },
    {
        key: "sa-menu-1",
        path: "/manage-companies",
        label: "Manage Companies",
        Icon: BusinessIcon,
    },
    {
        key: "sa-menu-2",
        path: "/manage-locations",
        label: "Manage Locations",
        Icon: LocationIcon,
    },
    {
        key: "sa-menu-3",
        path: "/manage-rooms",
        label: "Manage Meeting Rooms",
        Icon: MeetingRoomIcon,
    },
    {
        key: "sa-menu-4",
        path: "/manage-features",
        label: "Manage Features",
        Icon: FeaturesIcon,
    },
    {
        key: "sa-menu-5",
        path: "/manage-receptions",
        label: "Manage Receptions",
        Icon: ReceptionsIcon,
    },
    {
        key: "sa-menu-6",
        path: "/booking-report",
        label: "Booking Reports",
        Icon: BookingReportsIcon,
    },
];
const AdminMenu = [
    {
        key: "aa-menu-0",
        path: "/manage-users",
        label: "Manage Users",
        Icon: PeopleIcon,
    },
    {
        key: "aa-menu-1",
        path: "/manage-companies",
        label: "Manage Companies",
        Icon: BusinessIcon,
    },
    {
        key: "aa-menu-3",
        path: "/manage-rooms",
        label: "Manage Meeting Rooms",
        Icon: MeetingRoomIcon,
    },
    {
        key: "aa-menu-4",
        path: "/manage-features",
        label: "Manage Features",
        Icon: FeaturesIcon,
    },
    {
        key: "aa-menu-5",
        path: "/manage-receptions",
        label: "Manage Receptions",
        Icon: ReceptionsIcon,
    },
    {
        key: "aa-menu-6",
        path: "/booking-report",
        label: "Booking Reports",
        Icon: BookingReportsIcon,
    },
];

const CompanyOwnerMenu = [
    {
        key: "co-menu-0",
        path: "/my-company",
        label: "My Company",
        Icon: BusinessIcon,
    },
    {
        key: "co-menu-1",
        path: "/manage-users",
        label: "Manage Users",
        Icon: PeopleIcon,
    },
    {
        key: "co-menu-2",
        path: "/company-canteen-report",
        label: "Canteen Reports",
        Icon: CanteensIcon,
    },
];

const CanteenManagerMenu = [
    {
        key: "cm-menu-0",
        path: "/canteen-report",
        label: "Canteen Reports",
        Icon: CanteensIcon,
    },
];

const canteenRoutes = ["/manage-canteens", "/manage-canteen-items", "/canteen-report"];

const MenuItems = () => {
    const dispatch = useDispatch();
    const user = useSelector((state) => state.user);
    const classes = useStyles();
    const history = useHistory();
    const location = useLocation();

    const [canteenMenuOpen, setCanteenMenuOpen] = React.useState(
        canteenRoutes.includes(location.pathname)
    );
    const handleCanteenMenuClick = () => {
        setCanteenMenuOpen(!canteenMenuOpen);
    };

    const CanteenCollapsibleMenu = () => (
        <>
            <ListItem button onClick={handleCanteenMenuClick}>
                <ListItemIcon>
                    <CanteensIcon/>
                </ListItemIcon>
                <ListItemText primary="Canteens"/>
                {canteenMenuOpen ? <ExpandLess/> : <ExpandMore/>}
            </ListItem>
            <Collapse in={canteenMenuOpen} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    <ListItem
                        button
                        className={classes.listItem}
                        component={NavLink}
                        to={"/manage-canteens"}
                        exact
                        activeClassName="active"
                    >
                        <ListItemText primary="Manage Canteens"/>
                    </ListItem>
                    <ListItem
                        button
                        className={classes.listItem}
                        component={NavLink}
                        to={"/manage-canteen-items"}
                        exact
                        activeClassName="active"
                    >
                        <ListItemText primary="Manage Canteen Items"/>
                    </ListItem>
                    <ListItem
                        button
                        className={classes.listItem}
                        component={NavLink}
                        to={"/canteen-report"}
                        exact
                        activeClassName="active"
                    >
                        <ListItemText primary="Canteen Reports"/>
                    </ListItem>
                </List>
            </Collapse>
        </>
    );

    const logoutHandler = (event) => {
        event.preventDefault();
        dispatch(unsetUser());
        history.push("/");
    };

    const renderMenu = (role) => {
        switch (role) {
            case "SuperAdmin":
                return (
                    <>
                        {SuperAdminMenu.map((item) => (
                            <ListItem
                                key={item.key}
                                button
                                className={classes.listItem}
                                component={NavLink}
                                to={item.path}
                                exact
                                activeClassName="active"
                            >
                                <ListItemIcon>
                                    <item.Icon/>
                                </ListItemIcon>
                                <ListItemText primary={item.label}/>
                            </ListItem>
                        ))}
                        <CanteenCollapsibleMenu/>
                    </>
                );
            case "Admin":
                return (
                    <>
                        {AdminMenu.map((item) => (
                            <ListItem
                                key={item.key}
                                button
                                className={classes.listItem}
                                component={NavLink}
                                to={item.path}
                                exact
                                activeClassName="active"
                            >
                                <ListItemIcon>
                                    <item.Icon/>
                                </ListItemIcon>
                                <ListItemText primary={item.label}/>
                            </ListItem>
                        ))}
                        <CanteenCollapsibleMenu/>
                    </>
                );
            case "CompanyManager":
            case "CompanyOwner":
                return (
                    <>
                        {CompanyOwnerMenu.map((item) => (
                            <ListItem
                                key={item.key}
                                button
                                className={classes.listItem}
                                component={NavLink}
                                to={item.path}
                                exact
                                activeClassName="active"
                            >
                                <ListItemIcon>
                                    <item.Icon/>
                                </ListItemIcon>
                                <ListItemText primary={item.label}/>
                            </ListItem>
                        ))}
                    </>
                );
            case "User":
                return <></>;
            case "CanteenManager":
                return (
                    <>
                        {CanteenManagerMenu.map((item) => (
                            <ListItem
                                key={item.key}
                                button
                                className={classes.listItem}
                                component={NavLink}
                                to={item.path}
                                exact
                                activeClassName="active"
                            >
                                <ListItemIcon>
                                    <item.Icon/>
                                </ListItemIcon>
                                <ListItemText primary={item.label}/>
                            </ListItem>
                        ))}
                    </>
                );
            default:
                return <></>;
        }
    };

    return (
        <div>
            <Toolbar className={classes.toolbar}>
                {
                    theme.logo ?
                        <NavLink to={"/"}><img src={theme.logo} alt="logo" style={{width: "100%"}}/>
                        </NavLink> :
                        <Typography className={classes.brand} noWrap component={NavLink} to="/">
                            bookey
                        </Typography>
                }

            </Toolbar>
            <Divider/>

            <List>
                <ListItem
                    button
                    className={classes.listItem}
                    component={NavLink}
                    to="/profile"
                    activeClassName="active"
                >
                    <ListItemAvatar>
                        {user.image ? (
                            <Avatar src={user.image}/>
                        ) : (
                            <Avatar>{`${user.firstName.charAt(0)}${user.lastName.charAt(0)}`}</Avatar>
                        )}
                    </ListItemAvatar>
                    <ListItemText
                        className={classes.noOverflow}
                        primary={`${user.firstName} ${user.lastName}`}
                        secondary={user.company.name || ""}
                    />
                </ListItem>
                <ListItem
                    button
                    className={classes.listItem}
                    component={NavLink}
                    to="/"
                    exact
                    activeClassName="active"
                >
                    <ListItemIcon>
                        <CalendarIcon/>
                    </ListItemIcon>
                    <ListItemText primary="Calendar"/>
                </ListItem>
                <ListItem
                    button
                    className={classes.listItem}
                    component={NavLink}
                    to="/meeting-rooms"
                    exact
                    activeClassName="active"
                >
                    <ListItemIcon>
                        <MeetingRoomIcon/>
                    </ListItemIcon>
                    <ListItemText primary="Meeting rooms"/>
                </ListItem>
                <ListItem className={classes.listItem} button component="button" onClick={logoutHandler}>
                    <ListItemIcon>
                        <LogoutIcon/>
                    </ListItemIcon>
                    <ListItemText primary="Logout"/>
                </ListItem>
                <Divider className={classes.bigDivider}/>
                {renderMenu(user.role)}
            </List>
        </div>
    );
};

export default MenuItems;
