import config from "./baseRequestConfig";
import {axiosInstance} from "./axios.service";

const getMyLocationsAndRoomsUrl = "/api/location/mine/";
const addBookingUrl = "/api/booking/";
const updateBookingUrl = "/api/booking/";
const deleteBookingUrl = "/api/booking/";

const getRoomsAvailabilityUrl = "/api/booking/getAvailableRooms/";
const getCurrentBookingsUrl = "/api/booking/room/";
const getCurrentBookingsByLocationUrl = "/api/booking/location/";
const getUsedTimeUrl = "/api/booking/getUsedTime/";

export const getMyLocationsAndRooms = () => {
    const token = config();
    const cfg = {
        headers: {...axiosInstance.defaults.headers, Authorization: token},
    };
    return axiosInstance
        .get(getMyLocationsAndRoomsUrl, cfg)
        .then((response) => response.data)
        .catch((error) => {
            throw error.response;
        });
};

export const getCurrentBookingsForRoom = (roomId) => {
    const token = config();
    const cfg = {
        headers: {...axiosInstance.defaults.headers, Authorization: token},
    };
    return axiosInstance
        .get(getCurrentBookingsUrl + roomId, cfg)
        .then((response) => response.data)
        .catch((error) => {
            throw error.response;
        });
};

export const getCurrentBookingsForLocation = (locationId) => {
    const token = config();
    const cfg = {
        headers: {...axiosInstance.defaults.headers, Authorization: token},
    };
    return axiosInstance
        .get(getCurrentBookingsByLocationUrl + locationId, cfg)
        .then((response) => response.data)
        .catch((error) => {
            throw error.response;
        });
};

export const addBooking = (booking) => {
    const token = config();
    const cfg = {
        headers: {...axiosInstance.defaults.headers, Authorization: token},
    };

    return axiosInstance
        .post(addBookingUrl, booking, cfg)
        .then((response) => response.data)
        .catch((error) => {
            throw error.response;
        });
};

export const updateBooking = (id, booking) => {
    const token = config();
    const cfg = {
        headers: {...axiosInstance.defaults.headers, Authorization: token},
    };
    return axiosInstance
        .put(updateBookingUrl + id, booking, cfg)
        .then((response) => response.data)
        .catch((error) => {
            throw error.response;
        });
};

export const deleteBooking = (id) => {
    const token = config();
    const cfg = {
        headers: {
            ...axiosInstance.defaults.headers,
            Authorization: token,
        },
    };
    return axiosInstance
        .delete(deleteBookingUrl + id, cfg)
        .then((response) => response.data)
        .catch((error) => {
            throw error.response;
        });
};

export const getRoomsAvailability = (booking) => {
    const token = config();
    const cfg = {
        headers: {...axiosInstance.defaults.headers, Authorization: token},
    };
    return axiosInstance
        .post(getRoomsAvailabilityUrl, booking, cfg)
        .then((response) => response.data)
        .catch((error) => {
            throw error.response;
        });
};
export const getUsedTimeForLocation = (locationId) => {
    const token = config();
    const cfg = {
        headers: {...axiosInstance.defaults.headers, Authorization: token},
    };
    return axiosInstance
        .get(getUsedTimeUrl + locationId, cfg)
        .then((response) => response.data)
        .catch((error) => {
            throw error.response;
        });
};
