import {createMuiTheme, colors} from "@material-ui/core";
import typography from "./typography";
import nwLoginBgImage from '../assets/nw.login-bg-image.png';
import nwLogo from '../assets/nw.logo.png';
import loginBgImage from "../assets/login-bg-image.png";

const defaultTheme = createMuiTheme({
    palette: {
        background: {
            dark: "#F4F6F8",
            paper: colors.common.white,
        },
        primary: {
            main: colors.indigo[500],
        },
        secondary: {
            main: colors.red[500],
        },
        text: {
            primary: colors.blueGrey[900],
            secondary: colors.blueGrey[600],
        },
    },
    typography,
    loginBg: loginBgImage
});

// E6D9BE, CBAD6E, 262643
const nwTheme = createMuiTheme({
    palette: {
        background: {
            dark: "#F4F6F8",
            paper: colors.common.white,
        },
        primary: {
            main: '#CBAD6E',
        },
        text: {
            primary: colors.blueGrey[900],
            secondary: colors.blueGrey[600],
        },
    },
    overrides: {
        MuiSvgIcon: {
            root: {
                color: '#262643',
            },
        },
    },
    typography,
    logo: nwLogo,
    loginBg: nwLoginBgImage
});


const getTheme = () => {
    const theme = process.env.REACT_APP_THEME || 'default';

    switch (theme) {
        case 'nw':
            return nwTheme;
        default:
            return defaultTheme
    }
}

export default getTheme();