import {
    ALL_ROOMS_REQUEST_SUCCESS,
    ADD_ROOM_SUCCESS,
    UPDATE_ROOM_SUCCESS,
    DELETE_ROOM_SUCCESS,
    RESET_ROOM_URL_SUCCESS,
    MULTIPLE_ROOMS_ACTIVE_SUCCESS,
    MULTIPLE_ROOMS_INACTIVE_SUCCESS,
    MULTIPLE_ROOMS_DELETE_SUCCESS,
} from "../constants/rooms";

const initialState = {
    rooms: [],
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case ALL_ROOMS_REQUEST_SUCCESS:
            return {
                rooms: action.payload,
            };
        case ADD_ROOM_SUCCESS:
            return {
                ...state,
                rooms: [...state.rooms, action.payload.room],
            };
        case UPDATE_ROOM_SUCCESS:
            return {
                rooms: state.rooms.map((item) => {
                    return action.payload.room.id === item.id
                        ? {...action.payload.room}
                        : {...item};
                }),
            };
        case RESET_ROOM_URL_SUCCESS:
            return {
                rooms: state.rooms.map((item) => {
                    return action.payload.room.id === item.id
                        ? {...item, code: action.payload.room.code}
                        : item
                }),
            };
        case DELETE_ROOM_SUCCESS:
            return {
                rooms: state.rooms.filter((item) => action.payload.id !== item.id),
            };
        case MULTIPLE_ROOMS_ACTIVE_SUCCESS:
            return {
                rooms: state.rooms.map((item) => {
                    return action.payload.idList.includes(item.id)
                        ? {...item, isActive: true}
                        : {...item};
                }),
            };
        case MULTIPLE_ROOMS_INACTIVE_SUCCESS:
            return {
                rooms: state.rooms.map((item) => {
                    return action.payload.idList.includes(item.id)
                        ? {...item, isActive: false}
                        : {...item};
                }),
            };
        case MULTIPLE_ROOMS_DELETE_SUCCESS:
            return {
                rooms: state.rooms.filter(
                    (item) => !action.payload.idList.includes(item.id)
                ),
            };
        default:
            return state;
    }
};
export default reducer;
