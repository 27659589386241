import React from "react";
import {useSelector} from "react-redux";
import moment from "moment";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Tooltip from "@material-ui/core/Tooltip";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemAvatar";
import IconButton from "@material-ui/core/IconButton";

import MeetingRoomIcon from "@material-ui/icons/MeetingRoom";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import PersonIcon from "@material-ui/icons/Person";
import PriceIcon from '@material-ui/icons/MonetizationOn';
import BusinessIcon from "@material-ui/icons/Business";
import InfoIcon from "@material-ui/icons/Info";
import CloseIcon from "@material-ui/icons/Close";
import EditIcon from "@material-ui/icons/Create";
import DeleteIcon from "@material-ui/icons/Delete";

import {makeStyles} from "@material-ui/styles";
import {getValueWithTax} from "../utils/taxUtils";

const useStyles = makeStyles((theme) => ({
    titleRoot: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButtons: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
    },
    iconButton: {
        color: theme.palette.grey[600],
        "&.Mui-disabled": {
            pointerEvents: "auto",
        },
    },
}));

const ViewBookingDetails = ({open, handleClose, booking, handleEdit, handleDelete}) => {
    const classes = useStyles();
    const user = useSelector((state) => state.user);
    return (
        <Dialog open={open} onClose={handleClose} aria-labelledby="rules-dialog-title" maxWidth="md">
            <DialogTitle className={classes.titleRoot}>
                <div className={classes.closeButtons}>
                    {user.id === booking.userId ||
                    ["Admin", "SuperAdmin"].includes(user.role) ||
                    (["CompanyOwner", "CompanyManager"].includes(user.role) &&
                        booking.user.company &&
                        user.company.id === booking.user.company.id) ? (
                        booking.bookingEnd >= new Date() || ["Admin", "SuperAdmin"].includes(user.role) ? (
                            <>
                                <IconButton
                                    aria-label="edit"
                                    className={classes.iconButton}
                                    onClick={(e) => {
                                        handleEdit(booking);
                                    }}
                                >
                                    <EditIcon/>
                                </IconButton>
                                <IconButton
                                    aria-label="delete"
                                    className={classes.iconButton}
                                    onClick={(e) => {
                                        handleDelete(booking.id);
                                    }}
                                >
                                    <DeleteIcon/>
                                </IconButton>
                            </>
                        ) : (
                            <>
                                <IconButton aria-label="edit" disabled className={classes.iconButton}>
                                    <Tooltip title="You can not delete or modify past bookings">
                                        <EditIcon/>
                                    </Tooltip>
                                </IconButton>
                                <IconButton aria-label="delete" disabled className={classes.iconButton}>
                                    <Tooltip title="You can not delete or modify past bookings">
                                        <DeleteIcon/>
                                    </Tooltip>
                                </IconButton>
                            </>
                        )
                    ) : (
                        ""
                    )}
                    <IconButton aria-label="close" className={classes.iconButton} onClick={handleClose}>
                        <CloseIcon/>
                    </IconButton>
                </div>
            </DialogTitle>
            <DialogContent>
                <List dense>
                    <ListItem>
                        <ListItemIcon>
                            <Tooltip title="Details" placement="left-end">
                                <InfoIcon/>
                            </Tooltip>
                        </ListItemIcon>
                        <ListItemText
                            primaryTypographyProps={{variant: "h3"}}
                            secondaryTypographyProps={{variant: "subtitle1"}}
                            primary={booking.name}
                            secondary={`${moment(booking.bookingStart).format("dddd, MMMM D")} ⋅ ${moment(
                                booking.bookingStart
                            ).format("HH:mm")} – ${moment(booking.bookingEnd).format("HH:mm")} (${moment(
                                booking.bookingEnd
                            ).diff(moment(booking.bookingStart), "minutes")} minutes)`}
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <Tooltip title="Meeting room name" placement="left-end">
                                <MeetingRoomIcon/>
                            </Tooltip>
                        </ListItemIcon>
                        <ListItemText primary={booking.room.name}/>
                    </ListItem>
                    <ListItem>
                        <Tooltip title="Location" placement="left-end">
                            <ListItemIcon>
                                <LocationOnIcon/>
                            </ListItemIcon>
                        </Tooltip>{" "}
                        <ListItemText primary={booking.room.location.name}/>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <Tooltip title="Created by" placement="left-end">
                                <PersonIcon/>
                            </Tooltip>
                        </ListItemIcon>
                        <ListItemText primary={`${booking.user.firstName} ${booking.user.lastName}`}/>
                    </ListItem>
                    {booking.user.company ? (
                        <ListItem>
                            <ListItemIcon>
                                <Tooltip title="Company" placement="left-end">
                                    <BusinessIcon/>
                                </Tooltip>
                            </ListItemIcon>
                            <ListItemText primary={`${booking.user.company.name}`}/>
                        </ListItem>
                    ) : (
                        ""
                    )}
                    <ListItem>
                        <ListItemIcon>
                            <Tooltip title="Price" placement="left-end">
                                <PriceIcon/>
                            </Tooltip>
                        </ListItemIcon>
                        <ListItemText primary={booking.bookingCost?.total ? `${booking.bookingCost.total} DKK (${getValueWithTax(booking.bookingCost.total)} DKK with tax)` : "-"}/>
                    </ListItem>
                </List>
            </DialogContent>
        </Dialog>
    );
};

export default ViewBookingDetails;
