import config from "./baseRequestConfig";
import {axiosInstance} from "./axios.service";

const getAllFeaturesUrl = "/api/feature/";
const addFeatureUrl = "/api/feature/";
const updateFeatureUrl = "/api/feature/";
const deleteFeatureUrl = "/api/feature/";

const setMultipleFeaturesActiveUrl = "/api/feature/set-active";
const setMultipleFeaturesInactiveUrl = "/api/feature/set-inactive";
const deleteMultipleFeaturesUrl = "/api/feature/delete";

export const getAllFeatures = () => {
  const token = config();
  const cfg = {
    headers: { ...axiosInstance.defaults.headers, Authorization: token },
  };
  return axiosInstance
    .get(getAllFeaturesUrl, cfg)
    .then((response) => response.data)
    .catch((error) => {
      throw error.response;
    });
};

export const addFeature = (feature) => {
  const token = config();
  const cfg = {
    headers: { ...axiosInstance.defaults.headers, Authorization: token },
  };
  return axiosInstance
    .post(addFeatureUrl, feature, cfg)
    .then((response) => response.data)
    .catch((error) => {
      throw error.response;
    });
};

export const updateFeature = (id, feature) => {
  const token = config();
  const cfg = {
    headers: { ...axiosInstance.defaults.headers, Authorization: token },
  };
  return axiosInstance
    .put(updateFeatureUrl + id, feature, cfg)
    .then((response) => response.data)
    .catch((error) => {
      throw error.response;
    });
};

export const deleteFeature = (id) => {
  const token = config();
  const cfg = {
    headers: {
      ...axiosInstance.defaults.headers,
      Authorization: token,
    },
  };
  return axiosInstance
    .delete(deleteFeatureUrl + id, cfg)
    .then((response) => response.data)
    .catch((error) => {
      throw error.response;
    });
};

export const setMultipleFeaturesActive = (idList) => {
  const token = config();
  const cfg = {
    headers: { ...axiosInstance.defaults.headers, Authorization: token },
  };
  return axiosInstance
    .put(setMultipleFeaturesActiveUrl, { items: idList }, cfg)
    .then((response) => response.data)
    .catch((error) => {
      throw error.response;
    });
};

export const setMultipleFeaturesInactive = (idList) => {
  const token = config();
  const cfg = {
    headers: { ...axiosInstance.defaults.headers, Authorization: token },
  };
  return axiosInstance
    .put(setMultipleFeaturesInactiveUrl, { items: idList }, cfg)
    .then((response) => response.data)
    .catch((error) => {
      throw error.response;
    });
};

export const deleteMultipleFeatures = (idList) => {
  const token = config();
  const cfg = {
    headers: { ...axiosInstance.defaults.headers, Authorization: token },
  };
  return axiosInstance
    .post(deleteMultipleFeaturesUrl, { items: idList }, cfg)
    .then((response) => response.data)
    .catch((error) => {
      throw error.response;
    });
};
