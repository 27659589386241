import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Zoom from "@material-ui/core/Zoom";

import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  closeButtons: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
  noPadding: {
    padding: 0,
    overflow: "hidden",

    "& :first-child": {
      padding: 0,
    },
  },
  image: {
    maxHeight: "70vh",
    maxWidth: "95vw",
    // width: theme.breakpoints.values.md - theme.spacing(6),
  },
  dialogPaper: {
    maxHeight: "70vh",
    maxWidth: "95vw",
    overflow: "hidden",
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Zoom direction="up" ref={ref} {...props} />;
});

const EnlargedImageDialog = ({ open, handleClose, imageLink }) => {
  const classes = useStyles();

  return (
    <Dialog
      className={classes.noPadding}
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      maxWidth="md"
      classes={{ paper: classes.dialogPaper }}
    >
      <DialogContent className={classes.noPadding} onClick={handleClose}>
        <img
          className={classes.image}
          src={imageLink}
          alt="enlarged"
        />
      </DialogContent>
    </Dialog>
  );
};

export default EnlargedImageDialog;
