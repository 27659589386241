import React from "react";
import {Redirect, Route, Switch} from "react-router-dom";
import {useSelector} from "react-redux";
import {useTracking} from "./hooks/useTracking";

import MainLayout from "./containers/MainLayout";
import CalendarTopbar from "./containers/CalendarTopbar";
import LoginPage from "./pages/LoginPage";
import ForgotPasswordPage from "./pages/ForgotPasswordPage";
import ResetPasswordPage from "./pages/ResetPasswordPage";
import UpdatePasswordPage from "./pages/UpdatePasswordPage";
import MainPage from "./pages/MainPage";
import ProfilePage from "./pages/ProfilePage";
import MeetingRoomsPage from "./pages/MeetingRoomsPage";

import ManageLocationsPage from "./pages/ManageLocationsPage";
import ManageMeetingRoomsPage from "./pages/ManageMeetingRoomsPage";
import ManageCompaniesPage from "./pages/ManageCompaniesPage";
import ManageUsersPage from "./pages/ManageUsersPage";
import ManageFeaturesPage from "./pages/ManageFeaturesPage";

import ManageCompanyUsers from "./pages/ManageCompanyUsers";
import CompanyPage from "./pages/CompanyPage";
import ManageCanteensPage from "./pages/ManageCanteensPage";
import ManageCanteenItems from "./pages/ManageCanteenItems";
import CanteenReportsPage from "./pages/CanteenReportsPage";
import CompanyCanteenReportsPage from "./pages/CompanyCanteenReportsPage";
import ManageReceptionsPage from "./pages/ManageReceptionsPage";
import BookingReportsPage from "./pages/BookingReportsPage";

const SuperAdminRoutes = [
    {key: "sa-route-0", path: "/manage-users", Component: ManageUsersPage},
    {
        key: "sa-route-1",
        path: "/manage-companies",
        Component: ManageCompaniesPage,
    },
    {
        key: "sa-route-2",
        path: "/manage-locations",
        Component: ManageLocationsPage,
    },
    {
        key: "sa-route-3",
        path: "/manage-rooms",
        Component: ManageMeetingRoomsPage,
    },
    {
        key: "sa-route-4",
        path: "/manage-features",
        Component: ManageFeaturesPage,
    },
    {
        key: "sa-route-5",
        path: "/manage-canteens",
        Component: ManageCanteensPage,
    },
    {
        key: "sa-route-6",
        path: "/manage-canteen-items",
        Component: ManageCanteenItems,
    },
    {
        key: "sa-route-7",
        path: "/canteen-report",
        Component: CanteenReportsPage,
    },
    {
        key: "sa-route-8",
        path: "/manage-receptions",
        Component: ManageReceptionsPage,
    },
    {
        key: "sa-route-9",
        path: "/booking-report",
        Component: BookingReportsPage,
    },
];

const AdminRoutes = [
    {key: "aa-route-0", path: "/manage-users", Component: ManageUsersPage},
    {
        key: "aa-route-1",
        path: "/manage-companies",
        Component: ManageCompaniesPage,
    },
    {
        key: "aa-route-3",
        path: "/manage-rooms",
        Component: ManageMeetingRoomsPage,
    },
    {
        key: "aa-route-4",
        path: "/manage-features",
        Component: ManageFeaturesPage,
    },
    {
        key: "aa-route-5",
        path: "/manage-canteens",
        Component: ManageCanteensPage,
    },
    {
        key: "aa-route-6",
        path: "/manage-canteen-items",
        Component: ManageCanteenItems,
    },
    {
        key: "aa-route-7",
        path: "/canteen-report",
        Component: CanteenReportsPage,
    },
    {
        key: "aa-route-8",
        path: "/manage-receptions",
        Component: ManageReceptionsPage,
    },
    {
        key: "aa-route-9",
        path: "/booking-report",
        Component: BookingReportsPage,
    },
];

const CompanyOwnerRoutes = [
    {key: "co-route-0", path: "/manage-users", Component: ManageCompanyUsers},
    {key: "co-route-1", path: "/my-company", Component: CompanyPage},
    {key: "co-route-2", path: "/company-canteen-report", Component: CompanyCanteenReportsPage},
];

const CanteenManagerRoutes = [
    {
        key: "cm-route-1",
        path: "/canteen-report",
        Component: CanteenReportsPage,
    },
];

const Routes = () => {
    useTracking("G-WYXQ0XL79Y");
    const isFirstLogin = useSelector((state) => state.user.firstLogin);
    const isAuthenticated = useSelector((state) => state.user.isAuthenticated);
    const role = useSelector((state) => state.user.role);

    const roleRoutes = (userRole) => {
        switch (userRole) {
            case "SuperAdmin":
                return SuperAdminRoutes.map((item) => (
                    <Route path={item.path} exact key={item.key}>
                        <MainLayout>
                            <item.Component/>
                        </MainLayout>
                    </Route>
                ));
            case "Admin":
                return AdminRoutes.map((item) => (
                    <Route path={item.path} exact key={item.key}>
                        <MainLayout>
                            <item.Component/>
                        </MainLayout>
                    </Route>
                ));
            case "CompanyManager":
            case "CompanyOwner":
                return CompanyOwnerRoutes.map((item) => (
                    <Route path={item.path} exact key={item.key}>
                        <MainLayout>
                            <item.Component/>
                        </MainLayout>
                    </Route>
                ));
            case "User":
                return <></>;
            case "CanteenManager":
                return CanteenManagerRoutes.map((item) => (
                    <Route path={item.path} exact key={item.key}>
                        <MainLayout>
                            <item.Component/>
                        </MainLayout>
                    </Route>
                ));
            default:
                return <></>;
        }
    };

    if (isAuthenticated && isFirstLogin) {
        return (
            <Switch>
                <Route path="/update-password" exact>
                    <UpdatePasswordPage/>
                </Route>
                <Redirect to="/update-password"/>
            </Switch>
        );
    }

    if (isAuthenticated) {
        return (
            <Switch>
                <Route path="/" exact>
                    <MainLayout topbarContent={<CalendarTopbar/>}>
                        <MainPage/>
                    </MainLayout>
                </Route>
                <Route path="/profile" exact>
                    <MainLayout paper={false}>
                        <ProfilePage/>
                    </MainLayout>
                </Route>
                <Route path="/meeting-rooms" exact>
                    <MainLayout>
                        <MeetingRoomsPage/>
                    </MainLayout>
                </Route>
                {roleRoutes(role)}
                <Redirect to="/"/>
            </Switch>
        );
    }

    return (
        <Switch>
            <Route path="/" exact>
                <LoginPage/>
            </Route>
            <Route path="/forgot-password" exact>
                <ForgotPasswordPage/>
            </Route>
            <Route path="/reset-password" exact>
                <ResetPasswordPage/>
            </Route>
            <Redirect to="/"/>
        </Switch>
    );
};

export default Routes;
