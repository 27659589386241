import axios from "axios";

const BASE_URL = process.env.REACT_APP_BE_URL;

console.log(process.env)

export const axiosInstance = axios.create({
        baseURL: BASE_URL,
        headers: {
            'Content-Type': 'application/json',
        },
    }
);