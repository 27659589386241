import * as yup from "yup";
import {Role} from "./role";

export const addLocationFormOptions = {
    initialValues: {
        name: "",
        description: "",
    },
    fields: [
        {
            type: "text",
            id: "name",
            label: "Name",
            name: "name",
            required: true,
        },
        {
            type: "textarea",
            id: "description",
            label: "Description",
            name: "description",
            required: true,
        },
        {
            type: "image",
            id: "image",
            label: "Image",
            name: "image",
            required: false,
        },
    ],
    validationSchema: yup.object({
        name: yup.string().required("Name is required"),
        description: yup.string().max(1500, "Too many characters").required("Description is required"),
    }),
};

export const editLocationFormOptions = {
    initialValues: {
        name: "",
        description: "",
    },
    fields: [
        {
            type: "text",
            id: "name",
            label: "Name",
            name: "name",
            required: true,
        },
        {
            type: "textarea",
            id: "description",
            label: "Description",
            name: "description",
            required: true,
        },
        {
            type: "image",
            id: "image",
            label: "Image",
            name: "image",
            required: false,
        },
    ],
    validationSchema: yup.object({
        name: yup.string().required("Name is required"),
        description: yup.string().max(1500, "Too many characters").required("Description is required"),
    }),
};

export const addCompanyFormOptions = {
    initialValues: {
        name: "",
        firstName: "",
        lastName: "",
        email: "",
        office: "",
        discount: "",
        locations: [],
    },
    fields: [
        {
            type: "text",
            id: "name",
            label: "Company name",
            name: "name",
            required: true,
        },
        {
            type: "text",
            id: "office",
            label: "Company Room Number",
            placeholder: "Room 201",
            name: "office",
            required: true,
        },
        {
            type: "multiple-select-checkboxes",
            id: "locations",
            label: "Locations",
            name: "locations",
            required: true,
            options: [],
        },
        {
            type: "image",
            id: "image",
            label: "Image",
            name: "image",
            required: false,
        },
        {
            type: "number",
            id: "discount",
            label: "Booking discount (%)",
            name: "discount",
            required: false,
        },
        {
            type: "text",
            id: "firstName",
            label: "Company administrator first name",
            name: "firstName",
            required: true,
        },
        {
            type: "text",
            id: "lastName",
            label: "Company administrator last name",
            name: "lastName",
            required: true,
        },
        {
            type: "text",
            id: "email",
            label: "Company administrator email",
            name: "email",
            required: true,
        },
    ],
    validationSchema: yup.object({
        name: yup.string().required("Company name is required"),
        firstName: yup.string().required("Company administrator first name is required"),
        lastName: yup.string().required("Company administrator last name is required"),
        email: yup
            .string()
            .email("Please provide a valid email")
            .required("Company administrator email is required"),
        office: yup.string().required("Company Room Number is required"),
        discount: yup.number().min(0, "Discount must be a positive number").max(100, "Discount must be at most 100%"),
        locations: yup.array().required("Please select locations"),
    }),
};

export const editCompanyFormOptions = {
    initialValues: {
        name: "",
        office: "",
        discount: "",
        locations: [],
    },
    fields: [
        {
            type: "text",
            id: "name",
            label: "Company name",
            name: "name",
            required: true,
        },
        {
            type: "text",
            id: "office",
            label: "Company Room Number",
            placeholder: "Room 201",
            name: "office",
            required: true,
        },
        {
            type: "number",
            id: "discount",
            label: "Booking discount (%)",
            name: "discount",
            required: false,
        },
        {
            type: "multiple-select-checkboxes",
            id: "locations",
            label: "Locations",
            name: "locations",
            required: true,
            options: [],
        },
        {
            type: "image",
            id: "image",
            label: "Image",
            name: "image",
            required: false,
        },
    ],
    validationSchema: yup.object({
        name: yup.string().required("Company name is required"),
        office: yup.string().required("Company Room Number is required"),
        locations: yup.array().required("Please select locations"),
    }),
};

export const addFeatureFormOptions = {
    initialValues: {
        name: "",
    },
    fields: [
        {
            type: "text",
            id: "name",
            label: "Name",
            name: "name",
            required: true,
        },
    ],
    validationSchema: yup.object({
        name: yup.string().required("Name is required"),
    }),
};

export const editFeatureFormOptions = {
    initialValues: {
        name: "",
    },
    fields: [
        {
            type: "text",
            id: "name",
            label: "Name",
            name: "name",
            required: true,
        },
    ],
    validationSchema: yup.object({
        name: yup.string().required("Name is required"),
    }),
};

export const addRoomFormOptions = {
    initialValues: {
        name: "",
        rules: "",
        seatsNumber: "",
        dailyLimit: "",
        weeklyLimit: "",
        hourlyRate: "",
        location: "",
        features: [],
    },
    fields: [
        {
            type: "text",
            id: "name",
            label: "Name",
            name: "name",
            required: true,
        },
        {
            type: "textarea",
            id: "rules",
            label: "Rules (each rule in new line)",
            name: "rules",
            required: true,
        },
        {
            type: "image",
            id: "image",
            label: "Image",
            name: "image",
            required: false,
        },
        {
            type: "number",
            id: "seatsNumber",
            label: "Number of seats",
            name: "seatsNumber",
            required: true,
        },
        {
            type: "number",
            id: "dailyLimit",
            label: "Maximum booked hours per day for a company",
            name: "dailyLimit",
            required: true,
        },
        {
            type: "number",
            id: "weeklyLimit",
            label: "Maximum booked hours per week for a company",
            name: "weeklyLimit",
            required: true,
        },
        {
            type: "dkk",
            id: "hourlyRate",
            label: "Booking hourly rate",
            name: "hourlyRate",
            required: false,
        },
        {
            type: "select",
            id: "location",
            label: "Room Location",
            name: "location",
            required: true,
            options: [],
        },
        {
            type: "multiple-select-checkboxes",
            id: "features",
            label: "Room Features",
            name: "features",
            required: false,
            options: [],
        },
    ],
    validationSchema: yup.object({
        name: yup.string().required("Room name is required"),
        rules: yup.string().max(1500, "Too many characters").required("Room rules are required"),
        seatsNumber: yup.number("Must be a number").min(0).required("Number of seats is required"),
        dailyLimit: yup.number("Must be a number").min(0).max(24).required("Required field"),
        weeklyLimit: yup
            .number("Must be a number")
            .min(yup.ref("dailyLimit"), "Must be equal or greater than daily limit")
            .required("Required field"),
        location: yup.number("").required("Required field"),
        features: yup.array(),
    }),
};

export const editRoomFormOptions = {
    initialValues: {
        name: "",
        rules: "",
        seatsNumber: "",
        dailyLimit: "",
        weeklyLimit: "",
        location: "",
        hourlyRate: "",
        features: [],
    },
    fields: [
        {
            type: "text",
            id: "name",
            label: "Name",
            name: "name",
            required: true,
        },
        {
            type: "textarea",
            id: "rules",
            label: "Rules (each rule in new line)",
            name: "rules",
            required: true,
        },
        {
            type: "image",
            id: "image",
            label: "Image",
            name: "image",
            required: false,
        },
        {
            type: "number",
            id: "seatsNumber",
            label: "Number of seats",
            name: "seatsNumber",
            required: true,
        },
        {
            type: "number",
            id: "dailyLimit",
            label: "Maximum booked hours per day for a company",
            name: "dailyLimit",
            required: true,
        },
        {
            type: "number",
            id: "weeklyLimit",
            label: "Maximum booked hours per week for a company",
            name: "weeklyLimit",
            required: true,
        },
        {
            type: "dkk",
            id: "hourlyRate",
            label: "Booking hourly rate",
            name: "hourlyRate",
            required: false,
        },
        {
            type: "select",
            id: "location",
            label: "Room Location",
            name: "location",
            required: true,
            options: [],
        },
        {
            type: "multiple-select-checkboxes",
            id: "features",
            label: "Room Features",
            name: "features",
            required: false,
            options: [],
        },
    ],
    validationSchema: yup.object({
        name: yup.string().required("Room name is required"),
        rules: yup.string().max(1500, "Too many characters").required("Room rules are required"),
        seatsNumber: yup
            .number("Must be a number")
            .min(0, "Must be a positive number")
            .required("Number of seats is required"),
        dailyLimit: yup
            .number("Must be a number")
            .min(0, "Must be a positive number")
            .max(24, "Must be maximum 24")
            .required("Required field"),
        weeklyLimit: yup
            .number("Must be a number")
            .min(yup.ref("dailyLimit"), "Must be equal or greater than daily limit")
            .required("Required field"),
        location: yup.number("").required("Required field"),
        features: yup.array(),
    }),
};

export const addUserFormOptions = {
    initialValues: {
        firstName: "",
        lastName: "",
        email: "",
        office: "",
        mobile: "",
        role: "",
        company: "",
    },
    fields: [
        {
            type: "text",
            id: "firstName",
            label: "First Name",
            name: "firstName",
            required: true,
        },
        {
            type: "text",
            id: "lastName",
            label: "Last Name",
            name: "lastName",
            required: true,
        },
        {
            type: "text",
            id: "email",
            label: "Email",
            name: "email",
            required: true,
        },
        // {
        //   type: "image",
        //   id: "image",
        //   label: "Image",
        //   name: "image",
        //   required: false,
        // },
        {
            type: "text",
            id: "office",
            label: "Employee Room Number",
            placeholder: "Room 201",
            name: "office",
            required: true,
        },
        {
            type: "text",
            id: "mobile",
            label: "Mobile Number",
            name: "mobile",
            required: true,
        },
        {
            type: "select",
            id: "role",
            label: "Role",
            name: "role",
            required: true,
            options: [
                {name: Role.User, value: "User"},
                {name: Role.CompanyManager, value: "CompanyManager"},
                {name: Role.CompanyOwner, value: "CompanyOwner"},
                {name: Role.Admin, value: "Admin"},
            ],
        },
        {
            type: "select",
            id: "company",
            label: "Company",
            name: "company",
            required: true,
            options: [],
        },
    ],
    validationSchema: yup.object({
        firstName: yup.string().required("First name is required"),
        lastName: yup.string().required("Last name is required"),
        email: yup.string().email("Must be an email").required("Email is required"),
        office: yup.string().required("Employee room number is required"),
        mobile: yup
            .string()
            .matches(/^\+[1-9][0-9]{1,14}$/g, "Please enter a valid international phone number")
            .trim()
            .required(),
        role: yup.string().oneOf(["Admin", "CompanyOwner", "User", "CompanyManager"]).required(),
        company: yup.number().required("Choose a company"),
    }),
};

export const editUserFormOptions = {
    initialValues: {
        firstName: "",
        lastName: "",
        email: "",
        office: "",
        mobile: "",
        role: "",
        company: "",
    },
    fields: [
        {
            type: "text",
            id: "firstName",
            label: "First Name",
            name: "firstName",
            required: true,
        },
        {
            type: "text",
            id: "lastName",
            label: "Last Name",
            name: "lastName",
            required: true,
        },
        {
            type: "text",
            id: "email",
            label: "Email",
            name: "email",
            required: true,
        },
        // {
        //   type: "image",
        //   id: "image",
        //   label: "Image",
        //   name: "image",
        //   required: false,
        // },
        {
            type: "text",
            id: "office",
            label: "Employee Room Number",
            placeholder: "Room 201",
            name: "office",
            required: true,
        },
        {
            type: "text",
            id: "mobile",
            label: "Mobile Number",
            name: "mobile",
            required: true,
        },
        {
            type: "select",
            id: "role",
            label: "Role",
            name: "role",
            required: true,
            options: [
                {name: Role.User, value: "User"},
                {name: Role.CompanyManager, value: "CompanyManager"},
                {name: Role.CompanyOwner, value: "CompanyOwner"},
                {name: Role.Admin, value: "Admin"},
            ],
        },
        {
            type: "select",
            id: "company",
            label: "Company",
            name: "company",
            required: true,
            options: [],
        },
    ],
    validationSchema: yup.object({
        firstName: yup.string().required("First name is required"),
        lastName: yup.string().required("Last name is required"),
        email: yup.string().email("Must be an email").required("Email is required"),
        office: yup.string().required("Employee room number is required"),
        mobile: yup
            .string()
            .matches(/^\+[1-9][0-9]{1,14}$/g, "Please enter a valid international phone number")
            .trim()
            .required(),
        role: yup.string().oneOf(["Admin", "CompanyOwner", "User", "CompanyManager"]).required(),
        company: yup.number().required("Choose a company"),
    }),
};

export const companyUserFormOptionsOwner = {
    initialValues: {
        firstName: "",
        lastName: "",
        email: "",
        office: "",
        mobile: "",
        role: "",
    },
    fields: [
        {
            type: "text",
            id: "firstName",
            label: "First Name",
            name: "firstName",
            required: true,
        },
        {
            type: "text",
            id: "lastName",
            label: "Last Name",
            name: "lastName",
            required: true,
        },
        {
            type: "text",
            id: "email",
            label: "Email",
            name: "email",
            required: true,
        },
        {
            type: "text",
            id: "office",
            label: "Office Location",
            name: "office",
            required: true,
        },
        {
            type: "text",
            id: "mobile",
            label: "Mobile Number",
            name: "mobile",
            required: true,
        },
        {
            type: "select",
            id: "role",
            label: "Role",
            name: "role",
            required: true,
            options: [
                {name: Role.User, value: "User"},
                {name: Role.CompanyManager, value: "CompanyManager"},
            ],
        },
    ],
    validationSchema: yup.object({
        firstName: yup.string().required("First name is required"),
        lastName: yup.string().required("Last name is required"),
        email: yup.string().email("Must be an email").required("Email is required"),
        office: yup.string().required("Office location is required"),
        mobile: yup
            .string()
            .matches(/^\+[1-9][0-9]{1,14}$/g, "Please enter a valid international phone number")
            .trim()
            .required(),
        role: yup.string().oneOf(["User", "CompanyManager"]).required(),
    }),
};

export const companyUserFormOptions = {
    initialValues: {
        firstName: "",
        lastName: "",
        email: "",
        office: "",
        mobile: "",
    },
    fields: [
        {
            type: "text",
            id: "firstName",
            label: "First Name",
            name: "firstName",
            required: true,
        },
        {
            type: "text",
            id: "lastName",
            label: "Last Name",
            name: "lastName",
            required: true,
        },
        {
            type: "text",
            id: "email",
            label: "Email",
            name: "email",
            required: true,
        },
        {
            type: "text",
            id: "office",
            label: "Office Location",
            name: "office",
            required: true,
        },
        {
            type: "text",
            id: "mobile",
            label: "Mobile Number",
            name: "mobile",
            required: true,
        },
    ],
    validationSchema: yup.object({
        firstName: yup.string().required("First name is required"),
        lastName: yup.string().required("Last name is required"),
        email: yup.string().email("Must be an email").required("Email is required"),
        office: yup.string().required("Office location is required"),
        mobile: yup
            .string()
            .matches(/^\+[1-9][0-9]{1,14}$/g, "Please enter a valid international phone number")
            .trim()
            .required(),
    }),
};

export const bookingValidationSchema = yup.object({
    name: yup.string().required("Booking title is required"),
    bookingDate: yup.date("Start date is required").required("Start date is required"),
    bookingStartTime: yup.date("Start time is required").required("Start time is required"),
    bookingEndTime: yup.date("End time is required").required("End time is required"),
});

export const addCanteenManagerFormOptions = {
    initialValues: {
        firstName: "",
        lastName: "",
        email: "",
        canteenId: "",
    },
    fields: [
        {
            type: "text",
            id: "firstName",
            label: "First Name",
            name: "firstName",
            required: true,
        },
        {
            type: "text",
            id: "lastName",
            label: "Last Name",
            name: "lastName",
            required: true,
        },
        {
            type: "text",
            id: "email",
            label: "Email",
            name: "email",
            required: true,
        },
        {
            type: "select",
            id: "canteenId",
            label: "Canteen",
            name: "canteenId",
            required: true,
            options: [],
        },
    ],
    validationSchema: yup.object({
        firstName: yup.string().required("First name is required"),
        lastName: yup.string().required("Last name is required"),
        email: yup.string().email("Must be an email").required("Email is required"),
        canteenId: yup.number().required("Choose a canteen"),
    }),
};

export const editCanteenManagerFormOptions = {
    initialValues: {
        firstName: "",
        lastName: "",
        email: "",
    },
    fields: [
        {
            type: "text",
            id: "firstName",
            label: "First Name",
            name: "firstName",
            required: true,
        },
        {
            type: "text",
            id: "lastName",
            label: "Last Name",
            name: "lastName",
            required: true,
        },
        {
            type: "text",
            id: "email",
            label: "Email",
            name: "email",
            required: true,
        },
    ],
    validationSchema: yup.object({
        firstName: yup.string().required("First name is required"),
        lastName: yup.string().required("Last name is required"),
        email: yup.string().email("Must be an email").required("Email is required"),
    }),
};

export const addCanteenFormOption = {
    initialValues: {
        password: "",
        locationId: "",
        printerEnabled: false,
    },
    fields: [
        {
            type: "password",
            id: "password",
            label: "Canteen Password",
            name: "password",
            required: true,
        },
        {
            type: "select",
            id: "locationId",
            label: "Canteen Location",
            name: "locationId",
            required: true,
            options: [],
        },
        {
            type: "checkbox",
            id: "printerEnabled",
            label: "Printer Enabled",
            name: "printerEnabled",
            required: true,
        },
    ],
    validationSchema: yup.object({
        password: yup.string().min(4, "Mut be at least 4 characters").required("Password is required"),
        locationId: yup.number().required("Choose a location"),
    }),
};

export const editCanteenFormOptions = {
    initialValues: {
        password: "",
        locationId: "",
        printerEnabled: false,
    },
    fields: [
        {
            type: "password",
            id: "password",
            label: "Canteen Password",
            name: "password",
            required: true,
        },
        {
            type: "select",
            id: "locationId",
            label: "Canteen Location",
            name: "locationId",
            required: true,
            options: [],
        },
        {
            type: "checkbox",
            id: "printerEnabled",
            label: "Printer Enabled",
            name: "printerEnabled",
            required: true,
        },
    ],
    validationSchema: yup.object({
        password: yup.string().min(4, "Mut be at least 4 characters").required("Password is required"),
        locationId: yup.number().required("Choose a location"),
    }),
};

export const addCanteenItemFormOptions = {
    initialValues: {
        name: "",
        price: "",
    },
    fields: [
        {
            type: "text",
            id: "name",
            label: "Name",
            name: "name",
            required: true,
        },
        {
            type: "number",
            id: "price",
            label: "Price",
            name: "price",
            required: true,
        },
        {
            type: "number",
            id: "position",
            label: "Position",
            name: "position",
            required: true,
        },
    ],
    validationSchema: yup.object({
        name: yup.string().required("Name is required"),
        price: yup.number().required("Price is required"),
        position: yup.number().required("Position is required"),
    }),
};

export const editCanteenItemFormOptions = {
    initialValues: {
        name: "",
        price: "",
    },
    fields: [
        {
            type: "text",
            id: "name",
            label: "Name",
            name: "name",
            required: true,
        },
        {
            type: "number",
            id: "price",
            label: "Price",
            name: "price",
            required: true,
        },
        {
            type: "number",
            id: "position",
            label: "Position",
            name: "position",
            required: true,
        },
    ],
    validationSchema: yup.object({
        name: yup.string().required("Name is required"),
        price: yup.number().required("Price is required"),
        position: yup.number().required("Position is required"),
    }),
};

export const companyApplicationFormOptions = {
    initialValues: {
        name: "",
        firstName: "",
        lastName: "",
        email: "",
        office: "",
        locations: [],
    },
    fields: [
        {
            type: "text",
            id: "name",
            label: "Company name",
            name: "name",
            required: true,
        },
        {
            type: "text",
            id: "office",
            label: "Company Room Number",
            placeholder: "Room 201",
            name: "office",
            required: true,
        },
        {
            type: "multiple-select-checkboxes",
            id: "locations",
            label: "Locations",
            name: "locations",
            required: true,
            options: [],
        },
        {
            type: "text",
            id: "firstName",
            label: "Company administrator first name",
            name: "firstName",
            required: true,
        },
        {
            type: "text",
            id: "lastName",
            label: "Company administrator last name",
            name: "lastName",
            required: true,
        },
        {
            type: "text",
            id: "email",
            label: "Company administrator email",
            name: "email",
            required: true,
        },
    ],
    validationSchema: yup.object({
        name: yup.string().required("Company name is required"),
        firstName: yup.string().required("Company administrator first name is required"),
        lastName: yup.string().required("Company administrator last name is required"),
        email: yup
            .string()
            .email("Please provide a valid email")
            .required("Company administrator email is required"),
        office: yup.string().required("Company Room Number is required"),
        locations: yup.array().required("Please select locations"),
    }),
};


export const addReceptionFormOptions = {
    initialValues: {
        password: "",
        locationId: "",
        name: "",
        wifiName: "",
        wifiPass: "",
    },
    fields: [
        {
            type: "password",
            id: "password",
            label: "Reception Password",
            name: "password",
            required: true,
        },
        {
            type: "select",
            id: "locationId",
            label: "Reception Location",
            name: "locationId",
            required: true,
            options: [],
        },
        {
            type: "text",
            id: "name",
            label: "Reception Name",
            name: "name",
            required: true,
        },
        {
            type: "text",
            id: "wifiName",
            label: "Reception Wifi Name",
            name: "wifiName",
            required: false,
        },
        {
            type: "text",
            id: "wifiPass",
            label: "Reception Wifi Password",
            name: "wifiPass",
            required: false,
        },
    ],
    validationSchema: yup.object({
        name: yup.string().min(1, "Mut be at least 1 character").required("Reception name is required"),
        wifiName: yup.string(),
        wifiPass: yup.string(),
        password: yup.string().min(4, "Mut be at least 4 characters").required("Password is required"),
        locationId: yup.number().required("Choose a location"),
    }),
};

export const editReceptionFormOptions = {
    initialValues: {
        password: "",
        locationId: "",
        name: "",
        wifiName: "",
        wifiPass: "",
    },
    fields: [
        {
            type: "password",
            id: "password",
            label: "Reception Password",
            name: "password",
            required: true,
        },
        {
            type: "select",
            id: "locationId",
            label: "Reception Location",
            name: "locationId",
            required: true,
            options: [],
        },
        {
            type: "text",
            id: "name",
            label: "Reception Name",
            name: "name",
            required: true,
        },
        {
            type: "text",
            id: "wifiName",
            label: "Reception Wifi Name",
            name: "wifiName",
            required: false,
        },
        {
            type: "text",
            id: "wifiPass",
            label: "Reception Wifi Password",
            name: "wifiPass",
            required: false,
        },
    ],
    validationSchema: yup.object({
        name: yup.string().min(1, "Mut be at least 1 character").required("Reception name is required"),
        wifiName: yup.string(),
        wifiPass: yup.string(),
        password: yup.string().min(4, "Mut be at least 4 character").required("Password is required"),
        locationId: yup.number().required("Choose a location"),
    }),
};
