export const ALL_ROOMS_REQUEST = "ALL_ROOMS_REQUEST";
export const ALL_ROOMS_REQUEST_SUCCESS = "ALL_ROOMS_REQUEST_SUCCESS";
export const ALL_ROOMS_REQUEST_FAILURE = "ALL_ROOMS_REQUEST_FAILURE";

export const ADD_ROOM = "ADD_ROOM";
export const ADD_ROOM_SUCCESS = "ADD_ROOM_SUCCESS";

export const UPDATE_ROOM = "UPDATE_ROOM";
export const UPDATE_ROOM_SUCCESS = "UPDATE_ROOM_SUCCESS";

export const DELETE_ROOM = "DELETE_ROOM";
export const DELETE_ROOM_SUCCESS = "DELETE_ROOM_SUCCESS";

export const MULTIPLE_ROOMS_ACTIVE = "MULTIPLE_ROOMS_ACTIVE";
export const MULTIPLE_ROOMS_ACTIVE_SUCCESS = "MULTIPLE_ROOMS_ACTIVE_SUCCESS";
export const MULTIPLE_ROOMS_INACTIVE = "MULTIPLE_ROOMS_INACTIVE";
export const MULTIPLE_ROOMS_INACTIVE_SUCCESS =
  "MULTIPLE_ROOMS_INACTIVE_SUCCESS";
export const MULTIPLE_ROOMS_DELETE = "MULTIPLE_ROOMS_DELETE";
export const MULTIPLE_ROOMS_DELETE_SUCCESS = "MULTIPLE_ROOMS_DELETE_SUCCESS";

export const RESET_ROOM_URL = "RESET_ROOM_URL";
export const RESET_ROOM_URL_SUCCESS = "RESET_ROOM_URL_SUCCESS";
