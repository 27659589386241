import {takeLatest, call, put} from "redux-saga/effects";
import {sendNotification} from "../../utils/sendNotification";

import {
    QUERY_BOOKING_REPORT_REQUEST_SUCCESS,
    QUERY_BOOKING_REPORT_REQUEST,
} from "../constants/booking";

import {
    REQUEST_STARTED,
    REQUEST_FAIL,
    REQUEST_SUCCESS,
    REQUEST_FINISHED,
} from "../constants/request";

import {
    queryBookingReport,
} from "../../services/booking.service";


export function* doRequestBookingReport(action) {
    try {
        const {payload} = action;
        yield put({type: REQUEST_STARTED});
        const data = yield call(queryBookingReport, payload.query);
        yield put({type: QUERY_BOOKING_REPORT_REQUEST_SUCCESS, payload: data});
        yield put({type: REQUEST_SUCCESS});
        sendNotification("Request successfully processed", "success");
    } catch (error) {
        sendNotification(error.data.message, "error");
        yield put({
            type: REQUEST_FAIL,
            payload: {
                message: error.data.message,
                statusCode: error.status,
            },
        });
    } finally {
        yield put({
            type: REQUEST_FINISHED,
        });
    }
}

export function* watchRequestBookingReport() {
    yield takeLatest(QUERY_BOOKING_REPORT_REQUEST, doRequestBookingReport);
}
