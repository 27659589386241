import config from "./baseRequestConfig";
import moment from "moment";
import {axiosInstance} from "./axios.service";

const getAllCanteensUrl = "/api/canteen/";
const addCanteenUrl = "/api/canteen/";
const updateCanteenUrl = "/api/canteen/";
const deleteCanteenUrl = "/api/canteen/";

const getAllCanteenItemsUrl = "/api/item/";
const addCanteenItemsUrl = "/api/item/";
const updateCanteenItemUrl = "/api/item/";
const deleteCanteenItemUrl = "/api/item/";

const queryCanteenReportUrl = "/api/order/canteen-report";
const queryCompanyCanteenReportUrl = "/api/order/company-report";

export const getAllCanteens = () => {
    const token = config();
    const cfg = {
        headers: {...axiosInstance.defaults.headers, Authorization: token},
    };
    return axiosInstance
        .get(getAllCanteensUrl, cfg)
        .then((response) => response.data)
        .catch((error) => {
            throw error.response;
        });
};

export const addCanteen = (canteen) => {
    const token = config();
    const cfg = {
        headers: {...axiosInstance.defaults.headers, Authorization: token},
    };
    return axiosInstance
        .post(addCanteenUrl, canteen, cfg)
        .then((response) => response.data)
        .catch((error) => {
            throw error.response;
        });
};

export const updateCanteen = (id, canteen) => {
    const token = config();
    const cfg = {
        headers: {...axiosInstance.defaults.headers, Authorization: token},
    };
    return axiosInstance
        .put(updateCanteenUrl + id, canteen, cfg)
        .then((response) => response.data)
        .catch((error) => {
            throw error.response;
        });
};

export const deleteCanteen = (id) => {
    const token = config();
    const cfg = {
        headers: {
            ...axiosInstance.defaults.headers,
            Authorization: token,
        },
    };
    return axiosInstance
        .delete(deleteCanteenUrl + id, cfg)
        .then((response) => response.data)
        .catch((error) => {
            throw error.response;
        });
};

export const getAllCanteenItems = () => {
    const token = config();
    const cfg = {
        headers: {...axiosInstance.defaults.headers, Authorization: token},
    };
    return axiosInstance
        .get(getAllCanteenItemsUrl, cfg)
        .then((response) => response.data)
        .catch((error) => {
            throw error.response;
        });
};

export const addCanteenItem = (item) => {
    const token = config();
    const cfg = {
        headers: {...axiosInstance.defaults.headers, Authorization: token},
    };
    return axiosInstance
        .post(addCanteenItemsUrl, item, cfg)
        .then((response) => response.data)
        .catch((error) => {
            throw error.response;
        });
};

export const updateCanteenItem = (id, item) => {
    const token = config();
    const cfg = {
        headers: {...axiosInstance.defaults.headers, Authorization: token},
    };
    return axiosInstance
        .put(updateCanteenItemUrl + id, item, cfg)
        .then((response) => response.data)
        .catch((error) => {
            throw error.response;
        });
};

export const deleteCanteenItem = (id) => {
    const token = config();
    const cfg = {
        headers: {
            ...axiosInstance.defaults.headers,
            Authorization: token,
        },
    };
    return axiosInstance
        .delete(deleteCanteenItemUrl + id, cfg)
        .then((response) => response.data)
        .catch((error) => {
            throw error.response;
        });
};

export const queryCanteenReport = (query) => {
    const token = config();
    const cfg = {
        headers: {...axiosInstance.defaults.headers, Authorization: token},
    };
    return axiosInstance
        .post(queryCanteenReportUrl, {
            ...query,
            startDate: moment(query.startDate).toJSON(),
            endDate: moment(query.endDate).toJSON(),
        }, cfg)
        .then((response) => response.data)
        .catch((error) => {
            throw error.response;
        });
};
export const queryCompanyCanteenReport = (query) => {
    const token = config();
    const cfg = {
        headers: {...axiosInstance.defaults.headers, Authorization: token},
    };
    return axiosInstance
        .post(queryCompanyCanteenReportUrl, {...query}, cfg)
        .then((response) => response.data)
        .catch((error) => {
            throw error.response;
        });
};
