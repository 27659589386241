import {axiosInstance} from "./axios.service";
import config from "./baseRequestConfig";

const myProfileUrl = "/api/user/me";
const updateProfileUrl = "/api/user/";
const resetPinUrl = "/api/user/reset-pin/";

export const getMyProfile = () => {
    const token = config();
    const cfg = {
        headers: {...axiosInstance.defaults.headers, Authorization: token},
    };
    return axiosInstance
        .get(myProfileUrl, cfg)
        .then((response) => response.data)
        .catch((error) => {
            throw error.response;
        });
};

export const updateProfile = (id, data) => {
    const token = config();
    var cfg;
    try {
        data.get("image");
        cfg = {
            headers: {
                ...axiosInstance.defaults.headers,
                Authorization: token,
                "Content-Type": "multipart/form-data",
            },
        };
    } catch (error) {
        cfg = {
            headers: {...axiosInstance.defaults.headers, Authorization: token},
        };
    }

    return axiosInstance
        .put(`${updateProfileUrl}/${id}`, data, cfg)
        .then((response) => response.data)
        .catch((error) => {
            throw error.response;
        });
};

export const resetPin = (id) => {
    const token = config();
    const cfg = {
        headers: {
            ...axiosInstance.defaults.headers,
            Authorization: token,
        },
    };
    return axiosInstance
        .post(resetPinUrl + id, null, cfg)
        .then((response) => response.data)
        .catch((error) => {
            throw error.response;
        });
};
