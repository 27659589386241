import config from "./baseRequestConfig";
import {axiosInstance} from "./axios.service";

const getAllRoomsUrl = "/api/room/";
const addRoomUrl = "/api/room/";
const updateRoomUrl = "/api/room/";
const deleteRoomUrl = "/api/room/";
const resetRoomCodeUrl = "/api/room/reset-code/";

const setMultipleRoomsActiveUrl = "/api/room/set-active";
const setMultipleRoomsInactiveUrl = "/api/room/set-inactive";
const deleteMultipleRoomsUrl = "/api/room/delete";

export const getAllRooms = () => {
    const token = config();
    const cfg = {
        headers: {...axiosInstance.defaults.headers, Authorization: token},
    };
    return axiosInstance
        .get(getAllRoomsUrl, cfg)
        .then((response) => response.data)
        .catch((error) => {
            throw error.response;
        });
};

export const addRoom = (room) => {
    const token = config();
    let cfg;
    try {
        room.get("image");
        cfg = {
            headers: {
                ...axiosInstance.defaults.headers,
                Authorization: token,
                "Content-Type": "multipart/form-data",
            },
        };
    } catch (error) {
        cfg = {
            headers: {...axiosInstance.defaults.headers, Authorization: token},
        };
    }
    return axiosInstance
        .post(addRoomUrl, room, cfg)
        .then((response) => response.data)
        .catch((error) => {
            throw error.response;
        });
};

export const updateRoom = (id, room) => {
    const token = config();
    let cfg;
    try {
        room.get("image");
        cfg = {
            headers: {
                ...axiosInstance.defaults.headers,
                Authorization: token,
                "Content-Type": "multipart/form-data",
            },
        };
    } catch (error) {
        cfg = {
            headers: {...axiosInstance.defaults.headers, Authorization: token},
        };
    }
    return axiosInstance
        .put(updateRoomUrl + id, room, cfg)
        .then((response) => response.data)
        .catch((error) => {
            throw error.response;
        });
};

export const deleteRoom = (id) => {
    const token = config();
    const cfg = {
        headers: {
            ...axiosInstance.defaults.headers,
            Authorization: token,
        },
    };
    return axiosInstance
        .delete(deleteRoomUrl + id, cfg)
        .then((response) => response.data)
        .catch((error) => {
            throw error.response;
        });
};

export const setMultipleRoomsActive = (idList) => {
    const token = config();
    const cfg = {
        headers: {...axiosInstance.defaults.headers, Authorization: token},
    };
    return axiosInstance
        .put(setMultipleRoomsActiveUrl, {items: idList}, cfg)
        .then((response) => response.data)
        .catch((error) => {
            throw error.response;
        });
};

export const setMultipleRoomsInactive = (idList) => {
    const token = config();
    const cfg = {
        headers: {...axiosInstance.defaults.headers, Authorization: token},
    };
    return axiosInstance
        .put(setMultipleRoomsInactiveUrl, {items: idList}, cfg)
        .then((response) => response.data)
        .catch((error) => {
            throw error.response;
        });
};

export const deleteMultipleRooms = (idList) => {
    const token = config();
    const cfg = {
        headers: {...axiosInstance.defaults.headers, Authorization: token},
    };
    return axiosInstance
        .post(deleteMultipleRoomsUrl, {items: idList}, cfg)
        .then((response) => response.data)
        .catch((error) => {
            throw error.response;
        });
};

export const resetRoomUrl = (id) => {
    const token = config();
    const cfg = {
        headers: {
            ...axiosInstance.defaults.headers,
            Authorization: token,
        },
    };
    return axiosInstance
        .post(resetRoomCodeUrl + id,null, cfg)
        .then((response) => response.data)
        .catch((error) => {
            throw error.response;
        });
};