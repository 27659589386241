import React from "react";

import { useSelector, useDispatch } from "react-redux";
import { ALL_ROOMS_REQUEST } from "../redux/constants/rooms";

import RulesDialog from "../components/RulesDialog";
import Loader from "../components/Loader";
import EnlargedImageDialog from "../components/EnlargedImageDialog";
import ClickToEnlarge from "../components/ClickToEnlarge";

import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import Chip from "@material-ui/core/Chip";

import ListIcon from "@material-ui/icons/List";
import { makeStyles } from "@material-ui/styles";

import MUIDataTable from "mui-datatables";
import TableTheme from "../utils/TableTheme";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import {getValueWithTax} from "../utils/taxUtils";

const useStyles = makeStyles((theme) => ({
  chips: { margin: theme.spacing(0.5) },
}));

const MeetingRoomsPage = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const rooms = useSelector((state) => state.rooms.rooms);
  const requesting = useSelector((state) => state.request.requesting);

  const [rulesDialogOpen, setRulesDialogOpen] = React.useState(false);
  const [currentOpenRules, setCurrentOpenRules] = React.useState("");

  const handleClickOpenRules = (rules) => {
    setRulesDialogOpen(true);
    setCurrentOpenRules(rules);
  };
  const handleCloseOpenRules = () => {
    setRulesDialogOpen(false);
    setCurrentOpenRules("");
  };

  const [enlargedImageDialogOpen, setEnlargedImageDialogOpen] = React.useState(false);
  const [currentEnlargedImage, setCurrentEnlargedImage] = React.useState("");

  const handleClickEnlargeImage = (imageLink) => {
    setEnlargedImageDialogOpen(true);
    setCurrentEnlargedImage(imageLink);
  };
  const handleCloseEnlargedImage = () => {
    setEnlargedImageDialogOpen(false);
    setCurrentEnlargedImage("");
  };

  React.useEffect(() => {
    dispatch({ type: ALL_ROOMS_REQUEST });
  }, [dispatch]);

  const data = rooms.map(
    ({ id, name, image, rules, seatsNumber, dailyLimit, weeklyLimit, ...room }) => {
      return {
        id,
        name,
        image,
        rules,
        seatsNumber,
        dailyLimit,
        weeklyLimit,
        hourlyRate: room.hourlyRate,
        locationName: room.location.name,
        featuresNames: room.features
          .filter((feature) => feature.isActive)
          .map((feature) => feature.name),
      };
    }
  );
  const columns = [
    {
      name: "id",
      options: {
        filter: false,
        sort: false,
        display: "excluded",
        print: false,
      },
    },
    {
      name: "image",
      label: "Image",
      options: {
        sort: false,
        download: false,
        filter: false,
        customHeadRender: () => (
          <th key={0} style={{ borderBottom: "1px solid rgba(224, 224, 224, 1)" }} />
        ),
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <ClickToEnlarge imageLink={value} handleClickEnlargeImage={handleClickEnlargeImage} />
            </>
          );
        },
      },
    },
    {
      name: "name",
      label: "Name",
      options: {
        filter: false,
      },
    },
    {
      name: "rules",
      label: "Rules",
      options: {
        sort: false,
        filter: false,
        download: false,
        print: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <Tooltip title="Click to view rules">
                <IconButton
                  onClick={() => {
                    handleClickOpenRules(value);
                  }}
                >
                  <ListIcon />
                </IconButton>
              </Tooltip>
            </>
          );
        },
      },
    },
    {
      name: "seatsNumber",
      label: "Number of seats",
      options: {
        filter: false,
      },
    },
    {
      name: "dailyLimit",
      label: "Daily limit",
      options: {
        filter: false,
        print: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <div>{value} hours</div>
            </>
          );
        },
      },
    },
    {
      name: "weeklyLimit",
      label: "Weekly limit",
      options: {
        filter: false,
        print: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <div>{value} hours</div>
            </>
          );
        },
      },
    },
    {
      name: "hourlyRate",
      label: "Hourly rate",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return value ? (
              <div>{value} DKK ({getValueWithTax(value)} with tax)</div>
          ) : <div>-</div>;
        },
      },
    },
    {
      name: "locationName",
      label: "Location",
      options: {
        filter: false,
      },
    },
    {
      name: "featuresNames",
      label: "Features",
      options: {
        filter: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              {value.map((item) => (
                <div key={`feature-${item}-room-${tableMeta.rowData[0]}`}>
                  <Chip size="small" className={classes.chips} label={item} />
                </div>
              ))}
            </>
          );
        },
      },
    },
  ];
  const options = {
    filterType: "checkbox",
    print: false,
    download: false,
    selectableRows: "none",
    sortOrder: {
      name: "name",
      direction: "asc",
    },
  };

  if (requesting) {
    return <Loader />;
  }
  return (
    <div>
      <RulesDialog
        open={rulesDialogOpen}
        handleClose={handleCloseOpenRules}
        rules={currentOpenRules}
      />
      <EnlargedImageDialog
        open={enlargedImageDialogOpen}
        handleClose={handleCloseEnlargedImage}
        imageLink={currentEnlargedImage}
      />

      <MuiThemeProvider theme={TableTheme}>
        <MUIDataTable data={data} columns={columns} options={options} />
      </MuiThemeProvider>
    </div>
  );
};

export default MeetingRoomsPage;
