import config from "./baseRequestConfig";
import moment from "moment";
import {axiosInstance} from "./axios.service";

const queryBookingReportUrl = "/api/booking/booking-report";

export const queryBookingReport = (query) => {
    const token = config();
    const cfg = {
        headers: {...axiosInstance.defaults.headers, Authorization: token},
    };
    return axiosInstance
        .post(queryBookingReportUrl, {
            ...query,
            startDate: moment(query.startDate).toJSON(),
            endDate: moment(query.endDate).toJSON(),
        }, cfg)
        .then((response) => response.data)
        .catch((error) => {
            throw error.response;
        });
};
