import config from "./baseRequestConfig";
import {axiosInstance} from "./axios.service";

const getAllReceptionsUrl = "/api/reception/";
const addReceptionUrl = "/api/reception/";
const updateReceptionUrl = "/api/reception/";
const deleteReceptionUrl = "/api/reception/";

export const getAllReceptions = () => {
    const token = config();
    const cfg = {
        headers: {...axiosInstance.defaults.headers, Authorization: token},
    };
    return axiosInstance
        .get(getAllReceptionsUrl, cfg)
        .then((response) => response.data)
        .catch((error) => {
            throw error.response;
        });
};

export const addReception = (reception) => {
    const token = config();
    const cfg = {
        headers: {...axiosInstance.defaults.headers, Authorization: token},
    };
    return axiosInstance
        .post(addReceptionUrl, reception, cfg)
        .then((response) => response.data)
        .catch((error) => {
            throw error.response;
        });
};

export const updateReception = (id, reception) => {
    const token = config();
    const cfg = {
        headers: {...axiosInstance.defaults.headers, Authorization: token},
    };
    return axiosInstance
        .put(updateReceptionUrl + id, reception, cfg)
        .then((response) => response.data)
        .catch((error) => {
            throw error.response;
        });
};

export const deleteReception = (id) => {
    const token = config();
    const cfg = {
        headers: {
            ...axiosInstance.defaults.headers,
            Authorization: token,
        },
    };
    return axiosInstance
        .delete(deleteReceptionUrl + id, cfg)
        .then((response) => response.data)
        .catch((error) => {
            throw error.response;
        });
};
