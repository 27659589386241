import React, {useEffect} from "react";
import {Link} from "react-router-dom";

import {Field, Form, Formik} from "formik";
import {TextField} from "formik-material-ui";

import {makeStyles} from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import MuiLink from "@material-ui/core/Link";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";

import SubmitModal from "../containers/SubmitModal";
import {companyApplicationFormOptions} from "../services/formOptions";
import {ALL_LOCATIONS_REQUEST} from "../redux/constants/locations";
import {useDispatch, useSelector} from "react-redux";
import {createCompanyApplication} from "../redux/actions/company";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  image: {
    backgroundImage: `url(${theme.loginBg})`,
    backgroundRepeat: "no-repeat",
    backgroundColor:
        theme.palette.type === "light" ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const LoginComponent = ({ validationSchema, submitHandler, requesting, error }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [applicationDialogOpen, setApplicationDialogOpen] = React.useState(false);
  const locations = useSelector((state) => state.locations.locations);
  const locationsOptions = locations.map((location) => ({
    name: location.name,
    value: location.id,
  }));
  companyApplicationFormOptions.fields[
      companyApplicationFormOptions.fields.findIndex((field) => field.name === "locations")
      ].options = locationsOptions;

  useEffect(() => {
    dispatch({type: ALL_LOCATIONS_REQUEST});
  }, [dispatch]);

  const handleOpenApplicationDialog = () => {
    setApplicationDialogOpen(true)

  }
  const handleCloseApplicationDialog = () => {
    setApplicationDialogOpen(false)
  }
  const companyApplicationHandler = (data) => {
      dispatch(createCompanyApplication(data));
  }

  const dialogOptions = {
    title: "Apply for company account",
    descriptionText: "",
    formOptions: companyApplicationFormOptions,
    submitHandler: companyApplicationHandler,
    open: applicationDialogOpen,
    handleClose: handleCloseApplicationDialog,
  }

  return (
      <Grid container component="main" className={classes.root}>
        <Grid item xs={false} sm={4} md={7} className={classes.image}/>
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <div className={classes.paper}>
            <Avatar className={classes.avatar}>
              <LockOutlinedIcon/>
            </Avatar>
            <Typography component="h1" variant="h5">
              Sign in
            </Typography>
            <Formik
                initialValues={{
                  email: "",
                  password: "",
                }}
                validateOnChange={true}
                validationSchema={validationSchema}
                onSubmit={submitHandler}
            >
              {({submitForm, isSubmitting, status}) => (
                  <Form className={classes.form}>
                    <Field
                        component={TextField}
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  autoFocus
                />
                <Field
                  component={TextField}
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                />

                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  disabled={requesting}
                  onClick={submitForm}
                >
                  Submit
                </Button>
                <Grid container justify="flex-end">
                  <Grid item xs>
                    <MuiLink variant="body2" component={Link} to="/forgot-password">
                      Forgot password?
                    </MuiLink>
                  </Grid>
                </Grid>
                    <Box mt={5}>
                      <Typography variant="body2" color="textSecondary" align="center">
                        {"Don't have an account? Contact your employer"}
                      </Typography>
                    </Box>
                    <Box mt={10} display="flex" justifyContent="flex-end">
                      <Button variant="contained" color="primary" onClick={handleOpenApplicationDialog}>
                        Apply for company account
                      </Button>
                    </Box>
                  </Form>
              )}
            </Formik>
          </div>
        </Grid>
        <SubmitModal {...dialogOptions} />
      </Grid>
  );
};

export default LoginComponent;
