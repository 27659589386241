import config from "./baseRequestConfig";
import {axiosInstance} from "./axios.service";

const getAllUsersUrl = "/api/user/";
const updateUserUrl = "/api/user/";
const deleteUserUrl = "/api/user/";
const deleteMultipleUsersUrl = "/api/user/delete";
const addUserUrl = "/api/user/";
const addCompanyUserUrl = "/api/user/register";
const addCanteenManagerUrl = "/api/user/register-canteen-manager";

export const getAllUsers = () => {
    const token = config();
    const cfg = {
        headers: {...axiosInstance.defaults.headers, Authorization: token},
    };
    return axiosInstance
        .get(getAllUsersUrl, cfg)
        .then((response) => response.data)
        .catch((error) => {
            throw error.response;
        });
};
export const addUser = (user) => {
    const token = config();
    let cfg;
    try {
        user.get("image");
        cfg = {
            headers: {
                ...axiosInstance.defaults.headers,
                Authorization: token,
                "Content-Type": "multipart/form-data",
            },
        };
    } catch (error) {
        cfg = {
            headers: {...axiosInstance.defaults.headers, Authorization: token},
        };
    }
    return axiosInstance
        .post(addUserUrl, user, cfg)
        .then((response) => response.data)
        .catch((error) => {
            throw error.response;
        });
};
export const addCompanyUser = (user) => {
    const token = config();
    const cfg = {
        headers: {...axiosInstance.defaults.headers, Authorization: token},
    };
    return axiosInstance
        .post(addCompanyUserUrl, user, cfg)
        .then((response) => response.data)
        .catch((error) => {
            throw error.response;
        });
};
export const addCanteenManagerUser = (user) => {
    const token = config();
    const cfg = {
        headers: {...axiosInstance.defaults.headers, Authorization: token},
    };
    return axiosInstance
        .post(addCanteenManagerUrl, user, cfg)
        .then((response) => response.data)
        .catch((error) => {
            throw error.response;
        });
};

export const updateUser = (id, company) => {
    const token = config();
    let cfg;
    try {
        company.get("image");
        cfg = {
            headers: {
                ...axiosInstance.defaults.headers,
                Authorization: token,
                "Content-Type": "multipart/form-data",
            },
        };
    } catch (error) {
        cfg = {
            headers: {...axiosInstance.defaults.headers, Authorization: token},
        };
    }
    return axiosInstance
        .put(updateUserUrl + id, company, cfg)
        .then((response) => response.data)
        .catch((error) => {
            throw error.response;
        });
};

export const deleteUser = (id) => {
    const token = config();
    const cfg = {
        headers: {
            ...axiosInstance.defaults.headers,
            Authorization: token,
        },
    };
    return axiosInstance
        .delete(deleteUserUrl + id, cfg)
        .then((response) => response.data)
        .catch((error) => {
            throw error.response;
        });
};

export const deleteMultipleUsers = (idList) => {
    const token = config();
    const cfg = {
        headers: {...axiosInstance.defaults.headers, Authorization: token},
    };
    return axiosInstance
        .post(deleteMultipleUsersUrl, {items: idList}, cfg)
        .then((response) => response.data)
        .catch((error) => {
            throw error.response;
        });
};
