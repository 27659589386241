import {
    QUERY_BOOKING_REPORT_REQUEST_SUCCESS,
    QUERY_BOOKING_REPORT_CLEANUP,
} from "../constants/booking";

const initialState = {
    bookingReport: {
        total: 0,
        totalWithDiscount: 0,
        bookings: []
    },
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case QUERY_BOOKING_REPORT_REQUEST_SUCCESS:
            return {
                ...state,
                bookingReport: action.payload,
            };
        case QUERY_BOOKING_REPORT_CLEANUP:
            return initialState;
        default:
            return state;
    }
};
export default reducer;
