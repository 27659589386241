import React, {useEffect, useState} from "react";
import moment from "moment";

import {useDispatch, useSelector} from "react-redux";
import {Workbook} from "exceljs";
import {saveAs} from "file-saver";

import {ALL_COMPANIES_REQUEST} from "../redux/constants/company";
import PageTitle from "../components/PageTitle";
import Loader from "../components/Loader";

import {makeStyles} from "@material-ui/core/styles";
import MUIDataTable from "mui-datatables";
import BookingReportsFilter from "../containers/BookingReportsFilter";
import {ALL_ROOMS_REQUEST} from "../redux/constants/rooms";
import {cleanupBookingReport, queryBookingReport} from "../redux/actions/booking";
import {getValueWithTax} from "../utils/taxUtils";

const useStyles = makeStyles((theme) => ({
    rightAlignHeader: {
        "& span": {
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "flex-end",
        },
    },
}));

const BookingReportsPage = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const rooms = useSelector((state) => state.rooms.rooms);
    const report = useSelector((state) => state.booking.bookingReport);
    const companies = useSelector((state) => state.companies.companies);
    const requesting = useSelector((state) => state.request.requesting);

    const [query, setQuery] = useState({
        roomId: "",
        companyId: "",
        bookingStatus: "COMPLETED",
        startDate: moment.utc().startOf("month"),
        endDate: moment.utc().endOf("month"),
    });
    useEffect(() => {
        dispatch({type: ALL_ROOMS_REQUEST});
        dispatch({type: ALL_COMPANIES_REQUEST});
        return () => {
            dispatch(cleanupBookingReport());
        };
    }, [dispatch]);

    const handleQueryRequest = () => {
        dispatch(queryBookingReport(query));
    };

    const columns = [
        {
            name: "id",
            label: "ID",
            options: {},
        },
        {
            name: "booking.room.name",
            label: "Room",
            options: {},
        },
        {
            name: "booking.user.firstName",
            label: "First Name",
            options: {},
        },
        {
            name: "booking.user.lastName",
            label: "Last Name",
            options: {},
        },
        {
            name: "booking.user.company.name",
            label: "Company",
            options: {},
        },
        {
            name: "booking.bookingStart",
            label: "Start",
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return moment(value).format("DD.MM.YYYY HH:mm");
                },
            },
        },
        {
            name: "booking.bookingEnd",
            label: "End",
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return moment(value).format("DD.MM.YYYY HH:mm");
                },
            },
        },
        {
            name: "bookingDurationHours",
            label: "Duration (hours)",
        },
        {
            name: "hourlyRate",
            label: "Hourly Rate",
            options: {
                setCellHeaderProps: () => ({
                    className: classes.rightAlignHeader,
                }),
                setCellProps: () => ({
                    align: "right",
                }),
                customBodyRender: (value, tableMeta, updateValue) => {
                    return value + " kr.";
                },
            },
        },
        {
            name: "total",
            label: "Total",
            options: {
                setCellHeaderProps: () => ({
                    className: classes.rightAlignHeader,
                }),
                setCellProps: () => ({
                    align: "right",
                }),
                customBodyRender: (value, tableMeta, updateValue) => {
                    return value + " kr.";
                },
            },
        },
    ];

    const options = {
        enableNestedDataAccess: ".",
        onDownload: () => {
            const data = report.bookings;
            const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
            const fileExtension = ".xlsx";
            const fileName = `booking-report-${moment(query.startDate).format("DD_MM_yyyy")}-${moment(query.endDate).format("DD_MM_yyyy")}`;

            const workbook = new Workbook();
            const summarySheet = workbook.addWorksheet("Company Summary");
            const detailsSheet = workbook.addWorksheet("Booking Details");

            // Define columns for summary sheet
            summarySheet.columns = [
                {header: "Company", key: "company", width: 30},
                {header: "Total Bookings", key: "totalBookings", width: 20},
                {header: "Discount Percentage", key: "discountPercentage", width: 20},
                {header: "Total Amount Before Tax", key: "totalAmount", width: 20},
                {header: "Total With Discount Before Tax", key: "totalDiscountedAmount", width: 30},
                {header: "Total After Tax", key: "totalAmountAfterTax", width: 20},
                {header: "Total With Discount After Tax", key: "totalDiscountedAmountAfterTax", width: 30},
            ];

            // Define columns for details sheet
            detailsSheet.columns = [
                {header: "Booking ID", key: "bookingId", width: 15},
                {header: "Room Name", key: "roomName", width: 20},
                {header: "User First Name", key: "userFirstName", width: 20},
                {header: "User Last Name", key: "userLastName", width: 20},
                {header: "Company", key: "userCompanyName", width: 20},
                {header: "Booking Start", key: "bookingStart", width: 30},
                {header: "Booking End", key: "bookingEnd", width: 30},
                {header: "Booking Duration (hours)", key: "bookingDurationHours", width: 25},
                {header: "Hourly Rate", key: "hourlyRate", width: 15},
                {header: "Hourly Rate Discounted", key: "hourlyRateDiscounted", width: 25},
                {header: "Discount Percentage", key: "discountPercentage", width: 20},
                {header: "Total Amount Before Tax", key: "total", width: 20},
                {header: "Total With Discount Before Tax", key: "totalWithDiscount", width: 30},
                {header: "Total After Tax", key: "totalAmountAfterTax", width: 20},
                {header: "Total With Discount After Tax", key: "totalDiscountedAmountAfterTax", width: 30},
            ];

            // Prepare data for summary sheet
            const companySummary = {};
            data.forEach((booking) => {
                const companyName = booking.booking.user.company?.id ? `Company ${booking.booking.user.company.name}` : "No Company";
                if (!companySummary[companyName]) {
                    companySummary[companyName] = {
                        totalBookings: 0,
                        totalAmount: 0,
                        totalDiscountedAmount: 0,
                        discountPercentage: 0,
                    };
                }
                companySummary[companyName].totalBookings += 1;
                companySummary[companyName].totalAmount += Number(booking.total);
                companySummary[companyName].totalDiscountedAmount += Number(booking.totalWithDiscount);
                companySummary[companyName].discountPercentage = booking.discount;

            });

            for (const company in companySummary) {
                companySummary[company].totalAmountAfterTax = getValueWithTax(companySummary[company].totalAmount);
                companySummary[company].totalDiscountedAmountAfterTax = getValueWithTax(companySummary[company].totalDiscountedAmount);
            }

            // Add rows to summary sheet
            Object.keys(companySummary).forEach((company) => {
                summarySheet.addRow({
                    company,
                    totalBookings: companySummary[company].totalBookings,
                    totalAmount: companySummary[company].totalAmount,
                    totalDiscountedAmount: companySummary[company].totalDiscountedAmount,
                    discountPercentage: companySummary[company].discountPercentage,
                    totalAmountAfterTax: companySummary[company].totalAmountAfterTax,
                    totalDiscountedAmountAfterTax: companySummary[company].totalDiscountedAmountAfterTax,
                });
            });

            // Add rows to details sheet, sorted by companies
            data.sort((a, b) => a.booking.user.company?.name?.localeCompare(b.booking.user.company?.name))
                .forEach((booking) => {
                    detailsSheet.addRow({
                        bookingId: booking.id,
                        roomName: booking.booking.room.name,
                        userFirstName: booking.booking.user.firstName.trim(),
                        userLastName: booking.booking.user.lastName.trim(),
                        userCompanyName: booking.booking.user.company?.name ?? "No Company",
                        hourlyRate: Number(booking.hourlyRate),
                        bookingDurationHours: Number(booking.bookingDurationHours),
                        bookingStart: moment(booking.booking.bookingStart).format("DD.MM.YYYY HH:mm"),
                        bookingEnd: moment(booking.booking.bookingEnd).format("DD.MM.YYYY HH:mm"),
                        total: Number(booking.total),
                        totalWithDiscount: booking.totalWithDiscount,
                        discount: booking.discount,
                        hourlyRateDiscounted: Number(booking.hourlyRateDiscounted),
                        discountPercentage: booking.discount,
                        totalAmountAfterTax: getValueWithTax(booking.total),
                        totalDiscountedAmountAfterTax: getValueWithTax(booking.totalWithDiscount),
                    });
                });


            // Save the workbook
            workbook.xlsx.writeBuffer().then((buffer) => {
                const file = new Blob([buffer], {type: fileType});
                saveAs(file, fileName + fileExtension);
            });

            return false;
        },
        download: true,
        filterType: "checkbox",
        selectableRows: "none",
        filter: false,
        print: false,
        sortOrder: {
            name: "orderNumber",
            direction: "asc",
        },
    };

    return (
        <>
            <PageTitle title={"Booking reports"}/>
            <BookingReportsFilter
                rooms={rooms}
                companies={companies}
                query={query}
                setQuery={setQuery}
                handleRequest={handleQueryRequest}
            />
            {requesting ? <Loader/> : <MUIDataTable data={report.bookings} columns={columns} options={options}/>}
        </>
    );
};

export default BookingReportsPage;
