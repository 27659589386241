import {createMuiTheme} from "@material-ui/core/styles";
import globalTheme from "../theme";

const theme = createMuiTheme({
    ...globalTheme,
    overrides: {
        MUIDataTableBodyCell: {
            root: {
                textAlign: "center",
            },
        },
        MUIDataTableHeadCell: {
            root: {
                textAlign: "center",
            },
            toolButton: {
                justifyContent: "center",
                textAlign: "center",
            },
        },
    },
});

export default theme;
