import React, {useState} from 'react';
import {
    Button,
    IconButton,
    Snackbar,
    TextField,
    makeStyles
} from '@material-ui/core';
import {FileCopy as FileCopyIcon} from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(2),
        padding: theme.spacing(2),
    },
    urlContainer: {
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(1),
    },
    urlField: {
        flex: 1,
        minWidth: "350px"
    },
}));

const URLDisplay = ({url}) => {
    const classes = useStyles();
    const [isVisible, setIsVisible] = useState(false);
    const [showCopyNotification, setShowCopyNotification] = useState(false);

    const handleCopyClick = async () => {
        try {
            await navigator.clipboard.writeText(url);
            setShowCopyNotification(true);
        } catch (err) {
            console.error('Failed to copy URL:', err);
        }
    };

    const handleCloseNotification = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setShowCopyNotification(false);
    };

    return (
        <div className={classes.container}>
            <Button
                variant="outlined"
                color="primary"
                onClick={() => setIsVisible(!isVisible)}
            >
                {isVisible ? 'Hide URL' : 'Show URL'}
            </Button>

            {isVisible && (
                <div className={classes.urlContainer}>
                    <TextField
                        className={classes.urlField}
                        value={url}
                        variant="outlined"
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                    <IconButton
                        color="primary"
                        onClick={handleCopyClick}
                        aria-label="copy to clipboard"
                    >
                        <FileCopyIcon/>
                    </IconButton>
                </div>
            )}

            <Snackbar
                open={showCopyNotification}
                autoHideDuration={3000}
                onClose={handleCloseNotification}
                message="URL copied to clipboard!"
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            />
        </div>
    );
};

export default URLDisplay;