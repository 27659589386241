import {QUERY_BOOKING_REPORT_CLEANUP, QUERY_BOOKING_REPORT_REQUEST} from "../constants/booking";

export const queryBookingReport = (query) => {
    return {
        type: QUERY_BOOKING_REPORT_REQUEST,
        payload: { query },
    };
};

export const cleanupBookingReport = (query) => {
    return {
        type: QUERY_BOOKING_REPORT_CLEANUP,
    };
};
