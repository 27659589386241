import config from "./baseRequestConfig";
import {axiosInstance} from "./axios.service";

const storageName = "userData";
const loginUrl = "/api/user/login";
const refreshTokenUrl = "/api/user/refresh-token";
const revokeTokenUrl = "/api/user/revoke-token";

const forgotPasswordUrl = "/api/user/forgot-password";
const validateTokenUrl = "/api/user/validate-token";
const resetPasswordUrl = "/api/user/reset-password";

export const loginApi = (email, password) => {
    return axiosInstance
        .post(loginUrl, {email, password})
        .then((response) => response.data)
        .catch((error) => {
            throw error.response.data;
        });
};

export const refreshToken = () => {
    return axiosInstance
        .post(refreshTokenUrl)
        .then((response) => response.data)
        .catch((error) => {
            throw error.response.data;
        });
};

export const revokeToken = () => {
    const token = config();

    const cfg = {
        headers: {...axiosInstance.defaults.headers, Authorization: token},
    };
    return axiosInstance
        .post(revokeTokenUrl, null, cfg)
        .then((response) => response.data)
        .catch((error) => {
            throw error.response.data;
        });
};

export const getLocalAuthToken = () => {
    try {
        return JSON.parse(localStorage.getItem(storageName));
    } catch (error) {
        removeAuthToken();
        return;
    }
};

export const setAuthToken = (token) => {
    localStorage.setItem(storageName, JSON.stringify(token));
};

export const removeAuthToken = () => {
    localStorage.removeItem(storageName);
};

export const sendForgotPasswordRequest = (email) => {
    return axiosInstance
        .post(forgotPasswordUrl, email)
        .then((response) => response.data)
        .catch((error) => {
            throw error.response.data;
        });
};

export const validateTokenRequest = (token) => {
    return axiosInstance
        .post(validateTokenUrl, token)
        .then((response) => response.data)
        .catch((error) => {
            throw error.response.data;
        });
};

export const resetPasswordRequest = ({token, password, confirmPassword}) => {
    return axiosInstance
        .post(resetPasswordUrl, {token, password, confirmPassword})
        .then((response) => response.data)
        .catch((error) => {
            throw error.response.data;
        });
};
