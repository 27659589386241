import config from "./baseRequestConfig";
import {axiosInstance} from "./axios.service";

const getAllCompanyApplicationsUrl = "/api/company-application/";
const acceptCompanyApplicationsUrl = (id) => `/api/company-application/${id}/accept`;
const rejectCompanyApplicationsUrl = (id) => `/api/company-application/${id}/reject`;
const createCompanyApplicationsUrl = "/api/company-application/";

export const createCompanyApplication = (company) => {
    return axiosInstance
        .post(createCompanyApplicationsUrl, company)
        .then((response) => response.data)
        .catch((error) => {
            throw error.response;
        });
};

export const getAllCompanyApplications = () => {
    const token = config();
    const cfg = {
        headers: {...axiosInstance.defaults.headers, Authorization: token},
    };
    return axiosInstance
        .get(getAllCompanyApplicationsUrl, cfg)
        .then((response) => response.data)
        .catch((error) => {
            throw error.response;
        });
};
export const acceptCompany = (id) => {
    const token = config();
    const cfg = {
        headers: {
            ...axiosInstance.defaults.headers,
            Authorization: token,
        },
    };
    return axiosInstance
        .get(acceptCompanyApplicationsUrl(id), cfg)
        .then((response) => response.data)
        .catch((error) => {
            throw error.response;
        });
};

export const rejectCompany = (id) => {
    const token = config();
    const cfg = {
        headers: {
            ...axiosInstance.defaults.headers,
            Authorization: token,
        },
    };
    return axiosInstance
        .get(rejectCompanyApplicationsUrl(id), cfg)
        .then((response) => response.data)
        .catch((error) => {
            throw error.response;
        });
};
